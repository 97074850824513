<script>
import PavilionForm from "@/views/menu/pavilion/pavilionForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    pavilionService: "pavilionService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  }
})
export default class PavilionEdit extends mixins(PavilionForm) {
  formId = "edit-pavilion-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.pavilionService.update(this.model.id, {
        idArea: this.model.idArea,
        idInfoBox: this.model.idInfoBox,
        code: this.model.code,
        layout: this.model.layout,
        cardWithDescription: this.model.cardWithDescription,
        visualizeTagOnCards: this.model.visualizeTagOnCards,
        order: this.model.order,
        taxonomiesIds: this.model.taxonomiesIds,
        localizations: this.model.localizations,
      });
       this.$router.push("/pavilion/list");
      this.toastService.success(this.translations.success.pavilion_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.pavilions_edit;
  }

  async afterCreate () {
    this.model = await this.pavilionService.read(this.$route.params.id);
    this.internal_event_languages = await this.eventService.relatedLanguages(this.model.idEvent);
    this.isEditMode = true;
    this.isReady = true;
  }
}
</script>
